<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option d-none"></div>
  <button class="offerbtn d-none" onclick="myFunction()">
    <i class="fa fa-sliders"></i>
  </button>
  <div class="topnews d-none" id="MyOffer">
    <p class="m-0 text-center">{{Header1?.Description}}</p>
  </div>
  
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-4 col-md-2 col-lg-2">
          <div class="header-contact">
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <img [src]="env.mediaUrl+(companyService?.companyData?.Hotellist_Model?.HotelNameLoge || 'no-img.png')"
                  class="img-fluid logo" alt="logo" title="logo" width="140" height="54">
              </a>
            </div>
            <ul class="d-none">
              <!-- <li translate>Welcome to Our Rugbee Store </li> -->
              <li [textContent]="companyService?.companyData?.Hotellist_Model?.HotelName | slice:0:200">Lorem ipsum
                dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, </li>
              <li translate><i class="fa fa-phone" aria-hidden="true"></i>Call Us: <a
                  [attr.href]="'tel:'+companyService?.companyData?.Hotellist_Model?.ContactNo"
                  [textContent]="companyService?.companyData?.Hotellist_Model?.ContactNo"></a></li>
            </ul>
          </div>
        </div>
        <div class="col-8 col-md-10 col-lg-10">
          <div class=" d-flex align-items-center justify-content-end">
            <div class="header-search me-3" style="width: 30%;">
              <div class="input-group">
                <!-- <input class="form-control border-end-0 border rounded-pill" type="search" placeholder="Seach" [(ngModel)]="search" (keydown.enter)="searchItem($event)" (click)="remove($event)">
                <span class="input-group-append">
                    <button class="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5" style="z-index: 9;" type="button" [routerLink]="['/collections/all']" [queryParams]="{ search: search}">
                        <i class="fa fa-search"></i>
                    </button>
                </span> -->
                <!-- <ng-select [(ngModel)]="search" class="custom-select" name="selectproduct" (change)="selectChange()">
                  <ng-option *ngFor="let car of availableProduct" [value]="car.ItemName">{{car.ItemName | translate}}</ng-option>
                </ng-select> -->

                <ng-select [dropdownPosition]="'bottom'" id="ItemId" name="ItemId"
                  appendTo="body" class="custom-select" (change)="selectChange($event)">
                  <ng-template ng-header-tmp>
                    <div class="form-group has-feedback">
                      <input style="width: 100%;line-height: 24px" type="text" class="form-control" id="inputValidation"
                        placeholder="Search" [(ngModel)]="searchItem" [ngModelOptions]="{standalone: true}" />
                    </div>
                  </ng-template>
                  <ng-option *ngFor="let car of availableProduct | filterByImpure:['ItemName']:searchItem"
                    [value]="car.ItemName"> {{car.ItemName | translate}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="topright-menu">
              <ul class="header-dropdown">
                <li class="mobile-wishlist">
                  <button class="btn btn-solid bg-transparent border-0 p-0"
                    style="background-image: none; color: #000;font-weight: 400; text-transform: capitalize;"
                    *ngIf="this.code==1" (click)="loginvendor()"><i class="fa fa-unlock-alt"></i>Vendor Login</button>
                </li>
                <li class="mobile-wishlist">
                  <a title="wishlist" [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>{{'wishlist' | translate}}</a>
                </li>
                <li class="onhover-dropdown mobile-account">
                  <i class="fa fa-user" aria-hidden="true"></i> <span
                    [textContent]="authService.getUserName() || 'My Account'"></span>
                  <ul class="onhover-show-div">
                    <li *ngIf="!authService.isUserLoggedIn()">
                      <a data-lng="en" [routerLink]="['/auth/login']" title="login">
                        Login
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/dashboard']" title="Dashboard">
                        Dashboard
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/profile']" title="Profile">
                        Profile
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/orders']" title="History">
                        Order History
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/pages/wishlist']" title="Wishlist">
                        Wishlist
                      </a>
                    </li>
                    <li *ngIf="authService.isUserLoggedIn()">
                      <a data-lng="es" (click)="authService.logout()" title="Logout">
                        Logout
                      </a>
                    </li>
                    <li *ngIf="!authService.isUserLoggedIn()">
                      <a data-lng="es" [routerLink]="['/auth/register']" title="Register">
                        Register
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="seating-par top-seating-part">
                <app-settings></app-settings>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <!-- <div class="menu-left">
            <app-left-menu></app-left-menu>
          </div> -->

          <div class="menu-right pull-right">

            <div>
              <app-menu></app-menu>
            </div>


            <div class="seating-part d-none">
              <app-settings></app-settings>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</header>